import { t } from "../../translate";
import SignupLayout from "../../components/layouts/signup-layout";
import { ReactComponent as UserColorful } from "../../components/assets/ic_user_colorful.svg";
import { ReactComponent as CorpColorful } from "../../components/assets/ic_corp_colorful.svg";
import styles from "../../components/styles/signup-index.module.scss";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import TabButton from "../../components/atomics/tab-button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LinkButton from "../../components/atomics/link-button";
import { useQuery } from "../../helpers/use-query";
import PrimaryButton from "../../components/atomics/primary-button";
import { useForm } from "antd/lib/form/Form";
import { useSecureService } from "../../store/service-atoms";
import { useAtom } from "jotai";
import { loadingAtom, postloginMessageAtom, signUpAtom, userCredentialsAtom } from "../../store/global-atoms";
import UsernameInput from "../../components/atomics/username-input";
import { activeRootSiteUrl } from "../../store/app-constants";
import { agreementsVersions } from "../../store/agreements-versions";
import AgreementReadModal from "../../modals/agreement-read-modal";
import { AuthType, BasicModel, CategoryModel, CityModel, RequestFormCreateWithRegisterModel } from "../../service";
import moment from "moment";

type FormParams = {
    name: string;
    lastName: string;
    email: string;
    phone: string;
    companyName?: string;
    location: number;
    categories: [];
    totalMachines: string;
    password: string;
    agreementVersion: string;
    kvkkVersion: string;
    kvkkInfoVersion: string;
    ipazVersion: string;
};
const SignupForm = () => {
    const query = useQuery();
    const userRole = query.get("role");
    const fromRequest = query.get("request") ? "&request=true" : "";
    const [form] = useForm();
    const [isLessor, setIsLessor] = useState(userRole === "lessor");
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [, setLogin] = useAtom(userCredentialsAtom);
    const { doLessorSignup, doRenterSignup } = useSecureService();
    const [showReadAgreementModal, setShowReadAgreementModal] = useState<boolean>(false);
    const [selectedAgreementSrc, setSelectedAgreementSrc] = useState<string>();
    const [selectedAgreementTitle, setSelectedAgreementTitle] = useState<string>();
    const { doGetExactCities, doGetMachineSubcategory, doRenterRegisterWithRequest } = useSecureService();
    const [cities, setCities] = useState<CityModel[] | undefined>(undefined);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [categories, setCategories] = useState<CategoryModel[] | undefined>(undefined);
    const [selectedDistrict, setDistrict] = useState<string | undefined>(undefined);
    const [districts, setDistrictsDropdown] = useState<BasicModel[] | undefined>(undefined);
    const [signUpInfo] = useAtom(signUpAtom);
    const [selectedCity, setCity] = useState<
        | {
              id?: number | null;
              name?: string | null;
              districts?: BasicModel[] | null;
          }
        | undefined
    >(undefined);
    const { Option } = Select;
    const [isSocialSignup] = useState(signUpInfo?.authType === AuthType.Facebook || signUpInfo?.authType === AuthType.Google); 

    const description = isLessor ? t("signup.form.corporateDescription") : t("signup.form.retailDescription");
    
    useEffect(() => {
        doGetExactCities().then((result) => {
            setCities(result);
        });

        doGetMachineSubcategory().then((result) => {
            setCategories(result);
        });
        //eslint-disable-next-line
    }, []);

    const onFormFinished = async (params: FormParams) => {
        params.phone = params.phone.replace("+90", "");
        params.phone = params.phone.replace(/\s+/g, "");
        let id: number | undefined = undefined;
        if (fromRequest === "") {
            params.agreementVersion = agreementsVersions.agreementVersion;
            params.kvkkVersion = agreementsVersions.kvkkVersion;
            params.kvkkInfoVersion = agreementsVersions.kvkkInfoVersion; 
            if (params.ipazVersion) {
                params.ipazVersion = agreementsVersions.ipazVersion;
            }  
        }
        let categorieslist: any = params.categories?.map((catName: string) => categories?.find((cat: any) => cat.name === catName)?.id);
        params.categories = categorieslist;
        if (isLessor && !selectedCity) {
            return setErrorMessage({
                message: t("common.generic-required-location-message"),
                type: "error",
            });
        }
        else if (isLessor && !selectedDistrict) {
            return setErrorMessage({
                message: t("common.generic-required-district-message"),
                type: "error",
            });
        }
        let location = selectedCity?.districts?.find((dst) => dst.name === selectedDistrict)?.id;
        params.location = location ?? 0;
        params.totalMachines = params.totalMachines === "" ? "0" : params.totalMachines;
        setLogin({
            password: params.password,
            username: params.email,
        });
        if (isLessor) {
            if (params.companyName) {
                if (isSocialSignup)
                    id = await doLessorSignup({ ...params, 
                        authType: signUpInfo?.authType, 
                        email: signUpInfo?.email, 
                        locationId: location ?? 0,  companyName: params.companyName ,totalMachines: parseInt(params.totalMachines)});
                else 
                    id = await doLessorSignup({ ...params, 
                        authType: signUpInfo?.authType, 
                        locationId: location ?? 0,  
                        companyName: params.companyName,
                        totalMachines: parseInt(params.totalMachines)});
            }
        } else {
            if (isSocialSignup) {
                if (fromRequest !== '') {
                    if (signUpInfo?.request) {
                        var req : RequestFormCreateWithRegisterModel = {
                            ...signUpInfo.request,
                            requestDateFrom: moment(signUpInfo.request.requestDateFrom).toDate(),
                            requestDateTo: moment(signUpInfo.request.requestDateTo).toDate(),
                            renterUser: {
                                ...params, 
                                ...signUpInfo.request.renterUser,
                                phone: params.phone,
                                email: signUpInfo?.authType !== AuthType.Email ? signUpInfo?.email : null, 
                                sendActivationOTP: true
                            }
                        };
                        id = await doRenterRegisterWithRequest(req);
                    }
                } else {
                    id = await doRenterSignup({...params, 
                        authType: signUpInfo?.authType, 
                        email: signUpInfo?.email, sendActivationOTP: true});
                }
            }
            else
                id = await doRenterSignup({...params, authType: signUpInfo?.authType, sendActivationOTP: true});
        }

        if (id) {
            navigate("/sms-verification?id=" + id + "&op=register&phone=" + params.phone + "&role=" + userRole + fromRequest);
        }
    };

    const tabChange = (type: "lessor" | "customer") => {
        form.resetFields();
        navigate("/signup/form?role=" + type, { replace: true });
        setIsLessor(type === "lessor");
    };

    const handleSelectedAgreement = (showModal: boolean, linkSrc: string, title: string) => {
        setShowReadAgreementModal(showModal);
        setSelectedAgreementSrc(linkSrc);
        setSelectedAgreementTitle(title);
    };
    const onCitySelected = async (selectedCityName: string) => {
        setDistrict("");
        const currentCity = cities?.find((city) => city.name === selectedCityName);
        setCity(currentCity);
        if (currentCity && currentCity.districts) setDistrictsDropdown(currentCity.districts);
        else setDistrictsDropdown(undefined);
    };

    return (
        <SignupLayout descriptionBold={isSocialSignup} description={isSocialSignup ? t('signup.form.socialDescription') : description}>
            <Row hidden={isSocialSignup} className={styles.signupOptionsContainer} gutter={[12, 12]}>
                <Col xs={12}>
                    <TabButton
                        icon={<UserColorful height="3rem" width="5rem" />}
                        label={t("signup.index.firstCardTitle")}
                        active={!isLessor}
                        onClick={(e: any) => {
                            tabChange("customer");
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <TabButton
                        icon={<CorpColorful height="3rem" width="5rem" />}
                        label={t("signup.index.secondCardTitle")}
                        active={isLessor}
                        onClick={(e: any) => {
                            tabChange("lessor");
                        }}
                    />
                </Col>
            </Row>
            <Form layout="vertical" form={form} onFinish={onFormFinished}>
                <Row className={styles.signupOptionsContainer} style={{ textAlign: "left" }} gutter={[20, 12]}>
                    {(
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("signup.form.nameLabel")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.nameValidation"),
                                        pattern: /[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm,
                                    },
                                ]}
                            >
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder={t("signup.form.namePlaceholder")}
                                />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {(
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("signup.form.surnameLabel")}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.surnameValidation"),
                                        pattern: /[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm,
                                    },
                                ]}
                            >
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[A-Za-zıİğĞüÜşŞöÖçÇ]+/gm.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder={t("signup.form.surnamePlaceholder")}
                                />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {!isSocialSignup && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("signup.form.emailLabel")}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: t("signup.form.emailValidation"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("signup.form.emailPlaceholder")} />
                            </Form.Item>
                        </Col>
                        )
                    }
                    <Col xs={24} md={12}>
                        <UsernameInput prefix={false} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t("signup.form.corpLabel")}
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    type: "string",
                                    message: t("signup.form.corpValidation"),
                                },
                            ]}
                        >
                            <Input placeholder={t("signup.form.corpPlaceholder")} />
                        </Form.Item>
                    </Col>
                    {isLessor && (
                        <Col xs={24} md={12}>
                            <Form.Item name="location" label={t("signup.form.locationLabel")}>
                                <Row style={{ marginBottom: "1.5rem" }} justify="end" wrap={false}>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.city.placeholder")}
                                            loading={loading}
                                            onSelect={onCitySelected}
                                            style={{ width: "99%" }}
                                            value={selectedCity?.name}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                        >
                                            {cities?.map((mc) => (
                                                <Option value={mc.name}>{mc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Select
                                            placeholder={t("request.location.district.placeholder")}
                                            loading={loading}
                                            style={{ width: "99%" }}
                                            value={selectedDistrict}
                                            onSelect={(e: string) => setDistrict(e)}
                                            getPopupContainer={(trigger: any) => trigger.parentElement}
                                        >
                                            {districts?.map((msc) => (
                                                <Option value={msc.name}>{msc.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="categories"
                                label={t("signup.form.sectorLabel")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("signup.form.sectorValidation"),
                                    },
                                ]}
                            >
                                <Select getPopupContainer={(trigger: any) => trigger.parentElement} onKeyDown={(event) => {
                                    if (/[0-9]/.test(event.key))
                                        event.preventDefault();
                                }} mode="multiple" placeholder={t("common.select")}>
                                    {categories?.map((city) => (
                                        <Select.Option value={city.name}>{city.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("signup.form.totalMachinesLabel")}
                                name="totalMachines"
                                rules={[
                                    {
                                        type: "string",
                                        pattern: /^[0-9]*$/,
                                        message: t("common.only.number"),
                                    },
                                ]}
                            >
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    placeholder={t("signup.form.totalMachinesPlaceHolder")}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {!isSocialSignup && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={t("signup.form.passwordLabel")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        type: "string",
                                        message: t("signup.form.passwordValidation"),
                                    },
                                ]}
                            >
                                <Input type={"password"} placeholder={t("signup.form.passwordPlaceholder")} />
                            </Form.Item>
                        </Col>
                        )
                    }
                    {!isLessor && (
                        <Col hidden={fromRequest !== ""}xs={24}>
                            <Form.Item
                                name="agreementVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: fromRequest === "",
                                        type: "boolean",
                                        message: t("signup.form.bhsValidation"),
                                        validator: (_, value) =>
                                            value || fromRequest !== "" ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                        }
                                        target="_blank"
                                    >
                                        {t("signup.form.bhsName")}
                                    </LinkButton>
                                    <span> {t("signup.form.bhsLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {isLessor && (
                        <Col xs={24}>
                            <Form.Item
                                name="agreementVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.agreementValidation"),
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(true, activeRootSiteUrl + "/kullanici-sozlesmesi/", "agreementVersion")
                                        }
                                        target="_blank"
                                    >
                                        {t("signup.form.agreementName")}
                                    </LinkButton>{" "}
                                    <span>{t("signup.form.agreementLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    <Col hidden={!isLessor && fromRequest !== ""} xs={24}>
                        <Form.Item
                            name="kvkkVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.kvkkValidation"),
                                    required: fromRequest === "" || isLessor,
                                    validator: (_, value) =>
                                        value || fromRequest !== "" ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                },
                            ]}
                        >
                            <Checkbox>
                                <LinkButton
                                    onClick={() => handleSelectedAgreement(true, activeRootSiteUrl + "/aydinlatma-metni/", "kvkkVersion")}
                                    target="_blank"
                                >
                                    {t("signup.form.kvkkName")}
                                </LinkButton>
                                <span> {t("signup.form.kvkkLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    {isLessor && (
                        <Col xs={24}>
                            <Form.Item
                                name="kvkkInfoVersion"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        type: "boolean",
                                        message: t("signup.form.kvkkInfoValidation"),
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error(t("common.generic-required-message"))),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    <LinkButton
                                        onClick={() =>
                                            handleSelectedAgreement(
                                                true,
                                                activeRootSiteUrl + "/kurumsal-ve-bireysel-uye-acik-riza-metni/",
                                                "kvkkInfoVersion"
                                            )
                                        }
                                        target="_blank"
                                    >
                                        {t("signup.form.kvkkInfoName")}
                                    </LinkButton>
                                    <span>{t("signup.form.kvkkInfoLabel")}</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    <Col hidden={!isLessor && fromRequest !== ""} xs={24}>
                        <Form.Item
                            name="ipazVersion"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean",
                                    message: t("signup.form.ipazValidation"),
                                },
                            ]}
                        >
                            <Checkbox>
                                <LinkButton
                                    onClick={() =>
                                        handleSelectedAgreement(true, activeRootSiteUrl + "/izinli-pazarlama-onay-metni/", "ipazVersion")
                                    }
                                    target="_blank"
                                >
                                    {t("signup.form.ipazName")}
                                </LinkButton>
                                <span> {t("signup.form.ipazLabel")}</span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="center">
                    <Col xs={20} md={8}>
                        <PrimaryButton loading={loading} onClick={()=> {
                            console.log(JSON.stringify(form.validateFields().catch(e => console.log('eeee' + JSON.stringify(e)))));
                        }} htmlType="submit">
                            {t("signup.form.submit")}
                        </PrimaryButton>
                    </Col>
                </Row>
            </Form>
            {selectedAgreementTitle && selectedAgreementSrc && (
                <AgreementReadModal
                    show={showReadAgreementModal}
                    title={selectedAgreementTitle}
                    agreementSrc={selectedAgreementSrc}
                    onCloseClick={() => {
                        setShowReadAgreementModal(false);
                    }}
                    onButtonClick={() => {
                        form.setFieldsValue({ [selectedAgreementTitle]: true });
                        setShowReadAgreementModal(false);
                    }}
                />
            )}
        </SignupLayout>
    );
};

export default SignupForm;
