import { Col, Form, Input, Row } from "antd";
import { t } from "../../translate";

export type EmailInputProps = {
    initialValue?: string;
};

const EmailInput = (props: EmailInputProps) => {
    return (
        <Form.Item
            name="email"
            label={t("login.email.input-label")}
            rules={[
                {
                    type: "email",
                    message: t("login.email-error"),
                    required: true,
                },
            ]}
        >
            <Row>
                <Col xs={24}>
                    <Input placeholder={t("login.email.input-placeholder")} />
                </Col>
            </Row>
        </Form.Item>
    );
};

export default EmailInput;
