import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { AuthType, BasicModel, LessorPurpose, LoginRequestModel, LoginResponseModel, OfferFormViewModel, RequestFormCreateWithRegisterModel, RequestFormViewModel } from "../service";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";
import { OfferAttachmentObject } from "../pages/secure/offers/add";
import { Key } from "react";

export type ToastMessage = {
    message: string;
    type: "info" | "error" | "warning" | "success" | "loading";
};

export type OfferTabInfo = {
    lowestMaturitySelected: boolean;
    selectedLowestMaturityId: number;
    lowestSelectedMaturityShippingGoFee: number;
    lowestSelectedMaturityShippingReturnFee: number;
    lowestSelectedMaturityPeriodPrice: number | null;
    currencyType: string;
    currentTab: { name: string; index: number };
    maturityInterestRates: (number | null | undefined)[];
    currentTabTotalPrice: number;
    availableAttachments: BasicModel[] | undefined;
    selectedAttachments: OfferAttachmentObject[];
    tabTypes: number[];
    selectedPeriodType: number | undefined;
    includedMaturityOptions: boolean[];
    includedMaturityOptionsError: boolean[];
    requestedMachines: BasicModel[] | undefined;
    selectedMachines: BasicModel[] | undefined;
    editPage?: boolean;
};
export interface ModifiedRequestDetailModel extends RequestFormViewModel {
    fromDateRaw?: moment.Moment | null;
    toDateRaw?: moment.Moment | null;
    fuelTypeName?: string;
}
export interface ModifiedOfferDetailModel extends OfferFormViewModel {}
export const offerTabAtom = atom<OfferTabInfo>({
    lowestMaturitySelected: false,
    selectedLowestMaturityId: -1,
    currencyType: "",
    currentTab: { name: "", index: -1 },
    maturityInterestRates: Array(4).fill(-1),
    currentTabTotalPrice: 0,
    lowestSelectedMaturityShippingGoFee: 0,
    lowestSelectedMaturityShippingReturnFee: 0,
    lowestSelectedMaturityPeriodPrice: 0,
    availableAttachments: undefined,
    selectedAttachments: [],
    tabTypes: Array(4),
    selectedPeriodType: undefined,
    includedMaturityOptions: Array(4).fill(false),
    includedMaturityOptionsError: Array(4).fill(false),
    selectedMachines: undefined,
    requestedMachines: undefined,
});

export const loadingAtom = atom(false);
export const postloginMessageAtom = atom<ToastMessage>({
    message: "",
    type: "info",
});
export const userCredentialsAtom = atom<LoginRequestModel | undefined>(undefined);

export type SortingDataType = {
    icon: number;
    label: string;
    value: string;
};

export type MachineFilterType = {
    cityId?: number | null;
    rentalTypeId?: number | null;
    minPrice?: number | null;
    maxPrice?: number | null;
    districtId?: number | null;
    categoryIds?: number[];
    subCategoryIds?: number[];
    brandIds?: number[];
    hasExpertise?: boolean | undefined;
    selectedKeys?: Key[] | {
        checked: Key[];
        halfChecked: Key[];
    };
    order: string;
    activePage: number;
};

const filterStorage = createJSONStorage<MachineFilterType | undefined>(() => sessionStorage);
export const machineFilterAtom = atomWithStorage("machineFilter", undefined, filterStorage);

const userStorage = createJSONStorage<LoginResponseModel | undefined>(() => sessionStorage);
const lessorPurpose = createJSONStorage<LessorPurpose | undefined>(() => sessionStorage);
const selectedOption = createJSONStorage<string>(() => sessionStorage);

export const userAtom = atomWithStorage("user", undefined, userStorage);
export const unauthorizedResponseAtom = atom<string>("");
const requestFormStorage = createJSONStorage<ModifiedRequestDetailModel | undefined>(() => sessionStorage);
const extendedMachineStorage = createJSONStorage<ExtendedMachineDetailModel | undefined>(() => sessionStorage);
export const requestFormAtom = atomWithStorage<ModifiedRequestDetailModel | undefined>('offer-request', undefined, requestFormStorage);
export const extendedMachineDetailAtom = atomWithStorage<ExtendedMachineDetailModel | undefined>('extended-machine-detail', undefined, extendedMachineStorage);

export type RoleType = "lessor" | "customer";
// Local Storage
export const roleAtom = atomWithStorage<RoleType | undefined>("mg-role", undefined);
export const lessorPurposeAtom = atomWithStorage("lessorPurpose", undefined, lessorPurpose);
export const selectedOptionAtom = atomWithStorage("selectedOption", "", selectedOption);

export type SignUpType = {
    authType: AuthType;
    email: string;
    request?: RequestFormCreateWithRegisterModel;
}
export const signUpAtom = atomWithStorage<SignUpType | null>("signup", null);
