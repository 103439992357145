import { Col, Form, Radio, RadioChangeEvent, Row, Space } from "antd";
import PasswordInput from "../../components/atomics/password-input";
import PrimaryButton from "../../components/atomics/primary-button";
import TextButton from "../../components/atomics/text-button";
import AuthLayout from "../../components/layouts/auth-layout";
import { t } from "../../translate";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { RoleType, loadingAtom, postloginMessageAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { useEffect, useState } from "react";
import RadioRoleButton from "../../components/atomics/radio-role-button";
import { logoutChannel } from "../../App";
import EmailInput from "../../components/atomics/email-input";
import SocialAuthButton from "../../components/atomics/social-auth-button";
import { AuthType, SocialAuthPageType, SocialLoginMessageType } from "../../service";
import LinkButton from "../../components/atomics/link-button";
import { facebookLogin, googleLogin } from "../../utils/auth-utils";
import { facebookLoginActive, googleLoginActive, redirectLoginPageUri } from "../../store/app-constants";

const Login = () => {
    const { doLogin, doSocialLogin, doLogout } = useSecureService();
    const navigate = useNavigate();
    const [query, setQuery] = useSearchParams();
    const queryRole = ((query.get("role") || query.get("state")?.split('role:')[1]) as RoleType) ?? "lessor"; // 'state' holds roletype info for facebook redirection
    const socialLoginQuery = query.get("state")?.split('loginType:')[1].split(',')[0];
    const socialLoginType = (socialLoginQuery as AuthType) || null ; // code injected by facebook login dialog
    const socialLoginCode = query.get("code"); // code injected by facebook login dialog
    const queryLogout = query.get("isLogout");
    const [nonsecureRole, setNonsecureRole] = useState<RoleType | undefined>(undefined);
    const reservationId = query.get("reservationid");
    const machineId = query.get("machineId");
    const fromRequest = query.get("request") ? "&request=true" : "";
    const [loading] = useAtom(loadingAtom);
    const [,setWarningMessage] = useAtom(postloginMessageAtom);

    useEffect(() => {
        console.log(queryLogout, " ", queryRole);
        if (queryLogout && queryRole) {
            if (queryRole === "lessor") {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_LESSOR");
            } else {
                logoutChannel.postMessage("MG_LOGGED_OUT_NOTIFICATION_RENTER");
            }

            const logoutCall = async () => {
                await doLogout();
                navigate("/login?role=" + queryRole);
            };
            logoutCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryLogout, queryRole]);

    useEffect(() => {
        const socialLoginAsync = async (loginType: AuthType) => {
            var res = await doSocialLogin(queryRole, {authType: loginType, socialToken: socialLoginCode, socialAuthPageType: SocialAuthPageType.Login});
            switch(res?.type) {
                case SocialLoginMessageType.NotFound:
                case SocialLoginMessageType.SwitchMessage:
                case SocialLoginMessageType.Blocked:
                    setWarningMessage({message: res.message, type: 'error'});  
                    break;  
                case SocialLoginMessageType.Success:
                    navigate("/dashboard?role=" + queryRole + fromRequest);
                    break;
                case SocialLoginMessageType.Otp:
                    navigate("/sms-verification?id=" + res?.id + "&op=register&phone=" + res?.message + "&role=" + queryRole + fromRequest);
                    break;
            }
        }
        if (socialLoginCode && socialLoginType !== null) {
            socialLoginAsync(socialLoginType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log('ROLE:'+ JSON.stringify(queryRole));
        if (queryRole === "customer") {
            setNonsecureRole("customer");
        } else if (queryRole === "lessor") {
            setNonsecureRole("lessor");
        }
        //eslint-disable-next-line
    }, [queryRole]);

    const formFinishHandler = async (parameters: { email: string; password: string }) => {
        const result = await doLogin(queryRole, { password: parameters.password, username: parameters.email });
        if (result) {
            if (machineId && reservationId)
            {
                navigate("/reservation/detail?macid=" + machineId + "&reservationId=" + reservationId);
            }
            else {
                 navigate("/dashboard?role=" + queryRole + fromRequest);
            }
           
        }
    };

    const onChange = (e: RadioChangeEvent) => {
        if (reservationId) {
          setNonsecureRole(e.target.value);
          query.set("role", e.target.value);
          setQuery(query);  
        } 
    };

    const onRadioDivClicked = (param: string) => {
        if (!reservationId) {
            setNonsecureRole(param === "lessor" ? "lessor" : "customer");
            query.set("role", param);
            setQuery(query);
        }
    };

    return (
        <AuthLayout>
            <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                validateTrigger="onBlur"
                onFinish={formFinishHandler}
            >
                {!reservationId && 
                    <Row justify="center">
                        <span>{t("login.cardTitle")}</span>
                    </Row>
                }
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Radio.Group onChange={onChange} value={nonsecureRole}>
                        <Row style={{ justifyContent: "center", alignItems: "center" }}>
                            {!reservationId && (
                                <Col xs={12}>
                                    <RadioRoleButton
                                    radioClicked={onRadioDivClicked}
                                    value="lessor"
                                    content={t("common.makinesahibi")}
                                    isChecked={nonsecureRole === "lessor"}
                                    />
                                </Col>
                            )}
                            <Col xs={12}>
                                <RadioRoleButton
                                    radioClicked={onRadioDivClicked}
                                    value="customer"
                                    content={t("common.customer")}
                                    isChecked={nonsecureRole === "customer"}
                                />
                            </Col>
                        </Row>
                    </Radio.Group>
                </div>
                {nonsecureRole === undefined ? (
                    <></>
                ) : (
                    <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '12px'}} gutter={[0, 12]}>
                        <Row style={{width: '100%'}} gutter={[0,12]}>
                            <Col hidden={!facebookLoginActive} xs={24}>
                                <SocialAuthButton type="facebook" text={t("login.facebook.button-text")} onClick={() => {
                                    facebookLogin(redirectLoginPageUri, "loginType:" + AuthType.Facebook + ",role:" + queryRole);
                                }}/>
                            </Col>
                            <Col hidden={!googleLoginActive} xs={24}>
                                <SocialAuthButton type="google" text={t("login.google.button-text")} onClick={() => {
                                    googleLogin(redirectLoginPageUri, "loginType:" + AuthType.Google + ",role:" + queryRole);
                                }}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col hidden={!googleLoginActive && !facebookLoginActive} style={{textAlign: 'center'}} xs={24}>
                                <span style={{fontWeight: '600', fontSize: '14px', color: '#949394'}}>{t("common.or")}</span>
                            </Col>
                        </Row>
                        <Row style={{width: '100%', justifyContent: 'center'}}>
                            <Col xs={24}>
                                <EmailInput />
                            </Col>
                        </Row>
                        <Row style={{width: '100%', justifyContent: 'center', marginTop: '-16px'}}>
                            <Col xs={24}>
                                <PasswordInput
                                    placeholder={t("login.password.placeholder")}
                                    validationMessage={t("login.password.validationMessage")}
                                    label={t("login.password.label")}
                                />
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <TextButton loading={loading} onClick={() => navigate("/forgot-password?role=" + nonsecureRole)} type="link">
                                {t("login.forgotPassword")}
                            </TextButton>
                        </Row>
                        <Row>
                            <PrimaryButton loading={loading} htmlType="submit">
                                {nonsecureRole === "lessor" ? t("login.continue.lessor") : t("login.continue.renter")}
                            </PrimaryButton>
                        </Row>
                    </Row>
                )}
                {!reservationId && (
                    <Row justify="center">
                        <div style={{ textAlign: "center", fontSize: '14px' }}>
                            <Space>
                                {t("login.signup.init")}
                                <LinkButton style={{fontSize: '14px'}} loading={loading} onClick={() => navigate("/signup")}>
                                    {t("login.signup.link")}
                                </LinkButton>
                                <ArrowRightOutlined style={{ color: "#FEB111" }} />
                            </Space>
                        </div>
                        <div style={{ fontSize: '0.8rem', textAlign: "center" }}>
                            <span>
                                {t("login.agreement.part-1") + ' ' } 
                                <span onClick={() => navigate("/aydinlatma-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-2") + ' '}</span>
                                {t("login.agreement.part-3") + ' '} 
                                <span onClick={() => navigate("/kullanici-sozlesmesi")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-4")}</span>
                                {t("login.agreement.part-5") + ' '} 
                                <span onClick={() => navigate("/kurumsal-ve-bireysel-uye-acik-riza-metni")} style={{fontWeight: 800, textDecoration: 'underline', cursor: 'pointer'}}>{t("login.agreement.part-6")}</span>
                                {t("login.agreement.part-7")}
                            </span>
                        </div>
                    </Row>
                )}
            </Form>
        </AuthLayout>
    );
};

export default Login;
